"use strict";
!function (t) {
    function n(t) {
        var n = window.opener || window.parent;
        n.postMessage(JSON.stringify(t), e || "*")
    }

    t = t || (window.imanage = {});
    var e;
    t.ready = function (t) {
        return Q.promise(function (t, r) {
            function o(n) {
                try {
                    var r = JSON.parse(n.data).data;
                    r && r.config && r.config.serverBase === n.origin && (e = n.origin, t(r))
                } catch (o) {
                }
            }

            n({
                type: "ready",
                data: {}
            }), window.removeEventListener("message", o), window.addEventListener("message", o)
        })
    }, t.requestAuthNonce = function () {
        return Q.promise(function (t, e) {
            function r(n) {
                try {
                    var r = JSON.parse(n.data).data;
                    r && r.server_base === n.origin && (null === r.auth_nonce ? e("Unable to retrieve nonce for token.") : t(r))
                } catch (o) {
                }
            }

            n({type: "nonce"}), window.removeEventListener("message", r), window.addEventListener("message", r)
        })
    }, t.save = function (t) {
        return Q.promise(function (e, r) {
            n({type: "save", data: t})
        })
    }, t.cancel = function (t) {
        return Q.promise(function (e, r) {
            n({type: "cancel", data: t})
        })
    }, t.dialogs = {
        handler: function (t, n) {
            function e(n) {
                t && t(n.data)
            }

            var r = n || {};
            return window.addEventListener("message", e, r), function () {
                window.removeEventListener("message", e, r)
            }
        }
    }
}(window.imanage);
var _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
    return typeof t
} : function (t) {
    return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t
};
!function (t) {
    if ("function" == typeof bootstrap) bootstrap("promise", t); else if ("object" === ("undefined" == typeof exports ? "undefined" : _typeof(exports)) && "object" === ("undefined" == typeof module ? "undefined" : _typeof(module))) module.exports = t(); else if ("function" == typeof define && define.amd) define(t); else if ("undefined" != typeof ses) {
        if (!ses.ok()) return;
        ses.makeQ = t
    } else {
        if ("undefined" == typeof window && "undefined" == typeof self) throw new Error("This environment was not anticipated by Q. Please file a bug.");
        var n = "undefined" != typeof window ? window : self, e = n.Q;
        n.Q = t(), n.Q.noConflict = function () {
            return n.Q = e, this
        }
    }
}(function () {
    function t(t) {
        return function () {
            return z.apply(t, arguments)
        }
    }

    function n(t) {
        return t === Object(t)
    }

    function e(t) {
        return "[object StopIteration]" === rt(t) || t instanceof V
    }

    function r(t, n) {
        if (F && n.stack && "object" === ("undefined" == typeof t ? "undefined" : _typeof(t)) && null !== t && t.stack) {
            for (var e = [], r = n; r; r = r.source) r.stack && (!t.__minimumStackCounter__ || t.__minimumStackCounter__ > r.stackCounter) && (tt(t, "__minimumStackCounter__", {
                value: r.stackCounter,
                configurable: !0
            }), e.unshift(r.stack));
            e.unshift(t.stack);
            var i = e.join("\n" + ot + "\n"), u = o(i);
            tt(t, "stack", {value: u, configurable: !0})
        }
    }

    function o(t) {
        for (var n = t.split("\n"), e = [], r = 0; r < n.length; ++r) {
            var o = n[r];
            c(o) || i(o) || !o || e.push(o)
        }
        return e.join("\n")
    }

    function i(t) {
        return t.indexOf("(module.js:") !== -1 || t.indexOf("(node.js:") !== -1
    }

    function u(t) {
        var n = /at .+ \((.+):(\d+):(?:\d+)\)$/.exec(t);
        if (n) return [n[1], Number(n[2])];
        var e = /at ([^ ]+):(\d+):(?:\d+)$/.exec(t);
        if (e) return [e[1], Number(e[2])];
        var r = /.*@(.+):(\d+)$/.exec(t);
        return r ? [r[1], Number(r[2])] : void 0
    }

    function c(t) {
        var n = u(t);
        if (!n) return !1;
        var e = n[0], r = n[1];
        return e === $ && r >= q && r <= pt
    }

    function f() {
        if (F) try {
            throw new Error
        } catch (t) {
            var n = t.stack.split("\n"), e = n[0].indexOf("@") > 0 ? n[1] : n[2], r = u(e);
            if (!r) return;
            return $ = r[0], r[1]
        }
    }

    function s(t, n, e) {
        return function () {
            return "undefined" != typeof console && "function" == typeof console.warn && console.warn(n + " is deprecated, use " + e + " instead.", new Error("").stack), t.apply(t, arguments)
        }
    }

    function p(t) {
        return t instanceof y ? t : w(t) ? _(t) : R(t)
    }

    function a() {
        function t(t) {
            n = t, p.longStackSupport && F && (i.source = t), W(e, function (n, e) {
                p.nextTick(function () {
                    t.promiseDispatch.apply(t, e)
                })
            }, void 0), e = void 0, r = void 0
        }

        var n, e = [], r = [], o = Z(a.prototype), i = Z(y.prototype);
        if (i.promiseDispatch = function (t, o, i) {
            var u = K(arguments);
            e ? (e.push(u), "when" === o && i[1] && r.push(i[1])) : p.nextTick(function () {
                n.promiseDispatch.apply(n, u)
            })
        }, i.valueOf = function () {
            if (e) return i;
            var t = v(n);
            return m(t) && (n = t), t
        }, i.inspect = function () {
            return n ? n.inspect() : {state: "pending"}
        }, p.longStackSupport && F) try {
            throw new Error
        } catch (u) {
            i.stack = u.stack.substring(u.stack.indexOf("\n") + 1), i.stackCounter = it++
        }
        return o.promise = i, o.resolve = function (e) {
            n || t(p(e))
        }, o.fulfill = function (e) {
            n || t(R(e))
        }, o.reject = function (e) {
            n || t(S(e))
        }, o.notify = function (t) {
            n || W(r, function (n, e) {
                p.nextTick(function () {
                    e(t)
                })
            }, void 0)
        }, o
    }

    function l(t) {
        if ("function" != typeof t) throw new TypeError("resolver must be a function.");
        var n = a();
        try {
            t(n.resolve, n.reject, n.notify)
        } catch (e) {
            n.reject(e)
        }
        return n.promise
    }

    function d(t) {
        return l(function (n, e) {
            for (var r = 0, o = t.length; r < o; r++) p(t[r]).then(n, e)
        })
    }

    function y(t, n, e) {
        void 0 === n && (n = function (t) {
            return S(new Error("Promise does not support operation: " + t))
        }), void 0 === e && (e = function () {
            return {state: "unknown"}
        });
        var r = Z(y.prototype);
        if (r.promiseDispatch = function (e, o, i) {
            var u;
            try {
                u = t[o] ? t[o].apply(r, i) : n.call(r, o, i)
            } catch (c) {
                u = S(c)
            }
            e && e(u)
        }, r.inspect = e, e) {
            var o = e();
            "rejected" === o.state && (r.exception = o.reason), r.valueOf = function () {
                var t = e();
                return "pending" === t.state || "rejected" === t.state ? r : t.value
            }
        }
        return r
    }

    function h(t, n, e, r) {
        return p(t).then(n, e, r)
    }

    function v(t) {
        if (m(t)) {
            var n = t.inspect();
            if ("fulfilled" === n.state) return n.value
        }
        return t
    }

    function m(t) {
        return t instanceof y
    }

    function w(t) {
        return n(t) && "function" == typeof t.then
    }

    function k(t) {
        return m(t) && "pending" === t.inspect().state
    }

    function g(t) {
        return !m(t) || "fulfilled" === t.inspect().state
    }

    function j(t) {
        return m(t) && "rejected" === t.inspect().state
    }

    function b() {
        ut.length = 0, ct.length = 0, st || (st = !0)
    }

    function x(t, n) {
        st && ("object" === ("undefined" == typeof process ? "undefined" : _typeof(process)) && "function" == typeof process.emit && p.nextTick.runAfter(function () {
            X(ct, t) !== -1 && (process.emit("unhandledRejection", n, t), ft.push(t))
        }), ct.push(t), n && "undefined" != typeof n.stack ? ut.push(n.stack) : ut.push("(no stack) " + n))
    }

    function T(t) {
        if (st) {
            var n = X(ct, t);
            n !== -1 && ("object" === ("undefined" == typeof process ? "undefined" : _typeof(process)) && "function" == typeof process.emit && p.nextTick.runAfter(function () {
                var e = X(ft, t);
                e !== -1 && (process.emit("rejectionHandled", ut[n], t), ft.splice(e, 1))
            }), ct.splice(n, 1), ut.splice(n, 1))
        }
    }

    function S(t) {
        var n = y({
            when: function (n) {
                return n && T(this), n ? n(t) : this
            }
        }, function () {
            return this
        }, function () {
            return {state: "rejected", reason: t}
        });
        return x(n, t), n
    }

    function R(t) {
        return y({
            when: function () {
                return t
            }, get: function (n) {
                return t[n]
            }, set: function (n, e) {
                t[n] = e
            }, "delete": function (n) {
                delete t[n]
            }, post: function (n, e) {
                return null === n || void 0 === n ? t.apply(void 0, e) : t[n].apply(t, e)
            }, apply: function (n, e) {
                return t.apply(n, e)
            }, keys: function () {
                return et(t)
            }
        }, void 0, function () {
            return {state: "fulfilled", value: t}
        })
    }

    function _(t) {
        var n = a();
        return p.nextTick(function () {
            try {
                t.then(n.resolve, n.reject, n.notify)
            } catch (e) {
                n.reject(e)
            }
        }), n.promise
    }

    function E(t) {
        return y({
            isDef: function () {
            }
        }, function (n, e) {
            return D(t, n, e)
        }, function () {
            return p(t).inspect()
        })
    }

    function O(t, n, e) {
        return p(t).spread(n, e)
    }

    function Q(t) {
        return function () {
            function n(t, n) {
                var u;
                if ("undefined" == typeof StopIteration) {
                    try {
                        u = r[t](n)
                    } catch (c) {
                        return S(c)
                    }
                    return u.done ? p(u.value) : h(u.value, o, i)
                }
                try {
                    u = r[t](n)
                } catch (c) {
                    return e(c) ? p(c.value) : S(c)
                }
                return h(u, o, i)
            }

            var r = t.apply(this, arguments), o = n.bind(n, "next"), i = n.bind(n, "throw");
            return o()
        }
    }

    function N(t) {
        p.done(p.async(t)())
    }

    function C(t) {
        throw new V(t)
    }

    function P(t) {
        return function () {
            return O([this, A(arguments)], function (n, e) {
                return t.apply(n, e)
            })
        }
    }

    function D(t, n, e) {
        return p(t).dispatch(n, e)
    }

    function A(t) {
        return h(t, function (t) {
            var n = 0, e = a();
            return W(t, function (r, o, i) {
                var u;
                m(o) && "fulfilled" === (u = o.inspect()).state ? t[i] = u.value : (++n, h(o, function (r) {
                    t[i] = r, 0 === --n && e.resolve(t)
                }, e.reject, function (t) {
                    e.notify({index: i, value: t})
                }))
            }, void 0), 0 === n && e.resolve(t), e.promise
        })
    }

    function L(t) {
        if (0 === t.length) return p.resolve();
        var n = p.defer(), e = 0;
        return W(t, function (r, o, i) {
            function u(t) {
                n.resolve(t)
            }

            function c(t) {
                if (e--, 0 === e) {
                    var r = t || new Error("" + t);
                    r.message = "Q can't get fulfillment value from any promise, all promises were rejected. Last error message: " + r.message, n.reject(r)
                }
            }

            function f(t) {
                n.notify({index: i, value: t})
            }

            var s = t[i];
            e++, h(s, u, c, f)
        }, void 0), n.promise
    }

    function I(t) {
        return h(t, function (t) {
            return t = Y(t, p), h(A(Y(t, function (t) {
                return h(t, G, G)
            })), function () {
                return t
            })
        })
    }

    function U(t) {
        return p(t).allSettled()
    }

    function M(t, n) {
        return p(t).then(void 0, void 0, n)
    }

    function B(t, n) {
        return p(t).nodeify(n)
    }

    var F = !1;
    try {
        throw new Error
    } catch (J) {
        F = !!J.stack
    }
    var $, V, q = f(), G = function () {
    }, H = function () {
        function t() {
            for (var t, r; e.next;) e = e.next, t = e.task, e.task = void 0, r = e.domain, r && (e.domain = void 0, r.enter()), n(t, r);
            for (; c.length;) t = c.pop(), n(t);
            o = !1
        }

        function n(n, e) {
            try {
                n()
            } catch (r) {
                if (u) throw e && e.exit(), setTimeout(t, 0), e && e.enter(), r;
                setTimeout(function () {
                    throw r
                }, 0)
            }
            e && e.exit()
        }

        var e = {task: void 0, next: null}, r = e, o = !1, i = void 0, u = !1, c = [];
        if (H = function (t) {
            r = r.next = {task: t, domain: u && process.domain, next: null}, o || (o = !0, i())
        }, "object" === ("undefined" == typeof process ? "undefined" : _typeof(process)) && "[object process]" === process.toString() && process.nextTick) u = !0, i = function () {
            process.nextTick(t)
        }; else if ("function" == typeof setImmediate) i = "undefined" != typeof window ? setImmediate.bind(window, t) : function () {
            setImmediate(t)
        }; else if ("undefined" != typeof MessageChannel) {
            var f = new MessageChannel;
            f.port1.onmessage = function () {
                i = s, f.port1.onmessage = t, t()
            };
            var s = function () {
                f.port2.postMessage(0)
            };
            i = function () {
                setTimeout(t, 0), s()
            }
        } else i = function () {
            setTimeout(t, 0)
        };
        return H.runAfter = function (t) {
            c.push(t), o || (o = !0, i())
        }, H
    }(), z = Function.call, K = t(Array.prototype.slice), W = t(Array.prototype.reduce || function (t, n) {
        var e = 0, r = this.length;
        if (1 === arguments.length) for (; ;) {
            if (e in this) {
                n = this[e++];
                break
            }
            if (++e >= r) throw new TypeError
        }
        for (; e < r; e++) e in this && (n = t(n, this[e], e));
        return n
    }), X = t(Array.prototype.indexOf || function (t) {
        for (var n = 0; n < this.length; n++) if (this[n] === t) return n;
        return -1
    }), Y = t(Array.prototype.map || function (t, n) {
        var e = this, r = [];
        return W(e, function (o, i, u) {
            r.push(t.call(n, i, u, e))
        }, void 0), r
    }), Z = Object.create || function (t) {
        function n() {
        }

        return n.prototype = t, new n
    }, tt = Object.defineProperty || function (t, n, e) {
        return t[n] = e.value, t
    }, nt = t(Object.prototype.hasOwnProperty), et = Object.keys || function (t) {
        var n = [];
        for (var e in t) nt(t, e) && n.push(e);
        return n
    }, rt = t(Object.prototype.toString);
    V = "undefined" != typeof ReturnValue ? ReturnValue : function (t) {
        this.value = t
    };
    var ot = "From previous event:";
    p.resolve = p, p.nextTick = H, p.longStackSupport = !1;
    var it = 1;
    "object" === ("undefined" == typeof process ? "undefined" : _typeof(process)) && process && process.env && process.env.Q_DEBUG && (p.longStackSupport = !0), p.defer = a, a.prototype.makeNodeResolver = function () {
        var t = this;
        return function (n, e) {
            n ? t.reject(n) : arguments.length > 2 ? t.resolve(K(arguments, 1)) : t.resolve(e)
        }
    }, p.Promise = l, p.promise = l, l.race = d, l.all = A, l.reject = S, l.resolve = p, p.passByCopy = function (t) {
        return t
    }, y.prototype.passByCopy = function () {
        return this
    }, p.join = function (t, n) {
        return p(t).join(n)
    }, y.prototype.join = function (t) {
        return p([this, t]).spread(function (t, n) {
            if (t === n) return t;
            throw new Error("Q can't join: not the same: " + t + " " + n)
        })
    }, p.race = d, y.prototype.race = function () {
        return this.then(p.race)
    }, p.makePromise = y, y.prototype.toString = function () {
        return "[object Promise]"
    }, y.prototype.then = function (t, n, e) {
        function o(n) {
            try {
                return "function" == typeof t ? t(n) : n
            } catch (e) {
                return S(e)
            }
        }

        function i(t) {
            if ("function" == typeof n) {
                r(t, c);
                try {
                    return n(t)
                } catch (e) {
                    return S(e)
                }
            }
            return S(t)
        }

        function u(t) {
            return "function" == typeof e ? e(t) : t
        }

        var c = this, f = a(), s = !1;
        return p.nextTick(function () {
            c.promiseDispatch(function (t) {
                s || (s = !0, f.resolve(o(t)))
            }, "when", [function (t) {
                s || (s = !0, f.resolve(i(t)))
            }])
        }), c.promiseDispatch(void 0, "when", [void 0, function (t) {
            var n, e = !1;
            try {
                n = u(t)
            } catch (r) {
                if (e = !0, !p.onerror) throw r;
                p.onerror(r)
            }
            e || f.notify(n)
        }]), f.promise
    }, p.tap = function (t, n) {
        return p(t).tap(n)
    }, y.prototype.tap = function (t) {
        return t = p(t), this.then(function (n) {
            return t.fcall(n).thenResolve(n)
        })
    }, p.when = h, y.prototype.thenResolve = function (t) {
        return this.then(function () {
            return t
        })
    }, p.thenResolve = function (t, n) {
        return p(t).thenResolve(n)
    }, y.prototype.thenReject = function (t) {
        return this.then(function () {
            throw t
        })
    }, p.thenReject = function (t, n) {
        return p(t).thenReject(n)
    }, p.nearer = v, p.isPromise = m, p.isPromiseAlike = w, p.isPending = k, y.prototype.isPending = function () {
        return "pending" === this.inspect().state
    }, p.isFulfilled = g, y.prototype.isFulfilled = function () {
        return "fulfilled" === this.inspect().state
    }, p.isRejected = j, y.prototype.isRejected = function () {
        return "rejected" === this.inspect().state
    };
    var ut = [], ct = [], ft = [], st = !0;
    p.resetUnhandledRejections = b, p.getUnhandledReasons = function () {
        return ut.slice()
    }, p.stopUnhandledRejectionTracking = function () {
        b(), st = !1
    }, b(), p.reject = S, p.fulfill = R, p.master = E, p.spread = O, y.prototype.spread = function (t, n) {
        return this.all().then(function (n) {
            return t.apply(void 0, n)
        }, n)
    }, p.async = Q, p.spawn = N, p["return"] = C, p.promised = P, p.dispatch = D, y.prototype.dispatch = function (t, n) {
        var e = this, r = a();
        return p.nextTick(function () {
            e.promiseDispatch(r.resolve, t, n)
        }), r.promise
    }, p.get = function (t, n) {
        return p(t).dispatch("get", [n])
    }, y.prototype.get = function (t) {
        return this.dispatch("get", [t])
    }, p.set = function (t, n, e) {
        return p(t).dispatch("set", [n, e])
    }, y.prototype.set = function (t, n) {
        return this.dispatch("set", [t, n])
    }, p.del = p["delete"] = function (t, n) {
        return p(t).dispatch("delete", [n])
    }, y.prototype.del = y.prototype["delete"] = function (t) {
        return this.dispatch("delete", [t])
    }, p.mapply = p.post = function (t, n, e) {
        return p(t).dispatch("post", [n, e])
    }, y.prototype.mapply = y.prototype.post = function (t, n) {
        return this.dispatch("post", [t, n])
    }, p.send = p.mcall = p.invoke = function (t, n) {
        return p(t).dispatch("post", [n, K(arguments, 2)])
    }, y.prototype.send = y.prototype.mcall = y.prototype.invoke = function (t) {
        return this.dispatch("post", [t, K(arguments, 1)])
    }, p.fapply = function (t, n) {
        return p(t).dispatch("apply", [void 0, n])
    }, y.prototype.fapply = function (t) {
        return this.dispatch("apply", [void 0, t])
    }, p["try"] = p.fcall = function (t) {
        return p(t).dispatch("apply", [void 0, K(arguments, 1)])
    }, y.prototype.fcall = function () {
        return this.dispatch("apply", [void 0, K(arguments)])
    }, p.fbind = function (t) {
        var n = p(t), e = K(arguments, 1);
        return function () {
            return n.dispatch("apply", [this, e.concat(K(arguments))])
        }
    }, y.prototype.fbind = function () {
        var t = this, n = K(arguments);
        return function () {
            return t.dispatch("apply", [this, n.concat(K(arguments))])
        }
    }, p.keys = function (t) {
        return p(t).dispatch("keys", [])
    }, y.prototype.keys = function () {
        return this.dispatch("keys", [])
    }, p.all = A, y.prototype.all = function () {
        return A(this)
    }, p.any = L, y.prototype.any = function () {
        return L(this)
    }, p.allResolved = s(I, "allResolved", "allSettled"), y.prototype.allResolved = function () {
        return I(this)
    }, p.allSettled = U, y.prototype.allSettled = function () {
        return this.then(function (t) {
            return A(Y(t, function (t) {
                function n() {
                    return t.inspect()
                }

                return t = p(t), t.then(n, n)
            }))
        })
    }, p.fail = p["catch"] = function (t, n) {
        return p(t).then(void 0, n)
    }, y.prototype.fail = y.prototype["catch"] = function (t) {
        return this.then(void 0, t)
    }, p.progress = M, y.prototype.progress = function (t) {
        return this.then(void 0, void 0, t)
    }, p.fin = p["finally"] = function (t, n) {
        return p(t)["finally"](n)
    }, y.prototype.fin = y.prototype["finally"] = function (t) {
        if (!t || "function" != typeof t.apply) throw new Error("Q can't apply finally callback");
        return t = p(t), this.then(function (n) {
            return t.fcall().then(function () {
                return n
            })
        }, function (n) {
            return t.fcall().then(function () {
                throw n
            })
        })
    }, p.done = function (t, n, e, r) {
        return p(t).done(n, e, r)
    }, y.prototype.done = function (t, n, e) {
        var o = function (t) {
            p.nextTick(function () {
                if (r(t, i), !p.onerror) throw t;
                p.onerror(t)
            })
        }, i = t || n || e ? this.then(t, n, e) : this;
        "object" === ("undefined" == typeof process ? "undefined" : _typeof(process)) && process && process.domain && (o = process.domain.bind(o)), i.then(void 0, o)
    }, p.timeout = function (t, n, e) {
        return p(t).timeout(n, e)
    }, y.prototype.timeout = function (t, n) {
        var e = a(), r = setTimeout(function () {
            n && "string" != typeof n || (n = new Error(n || "Timed out after " + t + " ms"), n.code = "ETIMEDOUT"), e.reject(n)
        }, t);
        return this.then(function (t) {
            clearTimeout(r), e.resolve(t)
        }, function (t) {
            clearTimeout(r), e.reject(t)
        }, e.notify), e.promise
    }, p.delay = function (t, n) {
        return void 0 === n && (n = t, t = void 0), p(t).delay(n)
    }, y.prototype.delay = function (t) {
        return this.then(function (n) {
            var e = a();
            return setTimeout(function () {
                e.resolve(n)
            }, t), e.promise
        })
    }, p.nfapply = function (t, n) {
        return p(t).nfapply(n)
    }, y.prototype.nfapply = function (t) {
        var n = a(), e = K(t);
        return e.push(n.makeNodeResolver()), this.fapply(e).fail(n.reject), n.promise
    }, p.nfcall = function (t) {
        var n = K(arguments, 1);
        return p(t).nfapply(n)
    }, y.prototype.nfcall = function () {
        var t = K(arguments), n = a();
        return t.push(n.makeNodeResolver()), this.fapply(t).fail(n.reject), n.promise
    }, p.nfbind = p.denodeify = function (t) {
        if (void 0 === t) throw new Error("Q can't wrap an undefined function");
        var n = K(arguments, 1);
        return function () {
            var e = n.concat(K(arguments)), r = a();
            return e.push(r.makeNodeResolver()), p(t).fapply(e).fail(r.reject), r.promise
        }
    }, y.prototype.nfbind = y.prototype.denodeify = function () {
        var t = K(arguments);
        return t.unshift(this), p.denodeify.apply(void 0, t)
    }, p.nbind = function (t, n) {
        var e = K(arguments, 2);
        return function () {
            function r() {
                return t.apply(n, arguments)
            }

            var o = e.concat(K(arguments)), i = a();
            return o.push(i.makeNodeResolver()), p(r).fapply(o).fail(i.reject), i.promise
        }
    }, y.prototype.nbind = function () {
        var t = K(arguments, 0);
        return t.unshift(this), p.nbind.apply(void 0, t)
    }, p.nmapply = p.npost = function (t, n, e) {
        return p(t).npost(n, e)
    }, y.prototype.nmapply = y.prototype.npost = function (t, n) {
        var e = K(n || []), r = a();
        return e.push(r.makeNodeResolver()), this.dispatch("post", [t, e]).fail(r.reject), r.promise
    }, p.nsend = p.nmcall = p.ninvoke = function (t, n) {
        var e = K(arguments, 2), r = a();
        return e.push(r.makeNodeResolver()), p(t).dispatch("post", [n, e]).fail(r.reject), r.promise
    }, y.prototype.nsend = y.prototype.nmcall = y.prototype.ninvoke = function (t) {
        var n = K(arguments, 1), e = a();
        return n.push(e.makeNodeResolver()), this.dispatch("post", [t, n]).fail(e.reject), e.promise
    }, p.nodeify = B, y.prototype.nodeify = function (t) {
        return t ? void this.then(function (n) {
            p.nextTick(function () {
                t(null, n)
            })
        }, function (n) {
            p.nextTick(function () {
                t(n)
            })
        }) : this
    }, p.noConflict = function () {
        throw new Error("Q.noConflict only works when Q is used as a global")
    };
    var pt = f();
    return p
});
